import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
//  import Project from "./project/frontend/main"
import Start from './portfolio/frontend/Main';
// import Routing from './resumebuilder/routing';
// import File from "./nagarnigam/frontend/File"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Start></Start>

);

